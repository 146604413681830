import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { DataTable } from "../DataTable/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import ActionCell from "../ActionCell/ActionCell";
import { InviteForm } from "../InviteForm/InviteForm";
import { Invitation } from "../../Types/Invitation";
import { logger } from "../../Logger";
import { apiService } from "../../Services/ApiService";
import ReactPaginate from "react-paginate";
import { OnPageChangeEvent } from "../../Types/OnPageChangeEvent";
import { UserData } from "../../Types/UserData";
import { EmailContentProps } from "../../Types/EmailContent";

interface StaffListProps {}

const StaffList: FC<StaffListProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [data, setData] = useState<UserData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(10);
  const [pageSize] = useState(10); // Number of items per page

  const onEmailSend = (
    emailSubject: string,
    emailContent: string,
    userId: number
  ) => {
    logger.log(`Email subject: ${emailSubject}`);
    logger.log(`Email content: ${emailContent}`);
    logger.log(`User id: ${userId}`);
    const emailContentDto: EmailContentProps = {
      subject: emailSubject,
      content: emailContent,
    };

    apiService
      .sendEmailToUser(emailContentDto, userId)
      .then(() => {
        toast({
          title: "Email sent.",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Sending email error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const columnHelper = createColumnHelper<UserData>();
  const columns = [
    columnHelper.accessor("profile.name", {
      cell: (info) => info.getValue(),
      header: "Name",
    }),
    columnHelper.accessor("profile.surname", {
      cell: (info) => info.getValue(),
      header: "Surname",
    }),
    columnHelper.accessor("id", {
      cell: (info) => {
        return (
          <>
            <ActionCell
              id={info.getValue()!}
              route={`/staff/${info.getValue()}`}
              onEmailSend={onEmailSend}
            />
          </>
        );
      },
      header: "Actions",
    }),
  ];

  const handleClick = () => {
    onOpen();
  };

  const fetchStaff = useCallback(() => {
    logger.log(`Current selected page ${currentPage}`);
    apiService
      .getStaff({ page: currentPage, pageSize: pageSize })
      .then((response) => {
        logger.log(`Response: ${JSON.stringify(response.data.content)}`);
        setData(response.data.content);
        setTotalPages(response.data.totalPages);
      })
      .catch(() => {
        toast({
          title: "Fetching staff error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, [currentPage, pageSize, toast]);

  useEffect(() => {
    fetchStaff();
  }, [fetchStaff]);

  const onInvitationSubmit = async (data: Invitation, subsidiaryId: number) => {
    logger.log("Invitation submit called");
    logger.log(
      `Submiting ${JSON.stringify(data)} to subsidiary with id ${subsidiaryId}`
    );

    // if Everything is ok close the drawer
    await apiService
      .inviteNewStaff(data, subsidiaryId)
      .then((response) => {
        onClose();
        toast({
          title: "New staff invited.",
          description: "",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((reason) => {
        toast({
          title: "Inviting new staff error.",
          description: "",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handlePageChange = (selected: OnPageChangeEvent) => {
    setCurrentPage(selected.selected);
  };

  return (
    <Flex direction="column" p="16" bg="background.50" h="100%">
      <Box>
        <Heading as="h1" size="2xl">
          Staff
        </Heading>
      </Box>
      <Box mb="2" textAlign="right">
        <Button
          width="1xs"
          mt="4"
          bg="success.50"
          _hover={{ bg: "success.100" }}
          color="white"
          onClick={handleClick}
        >
          Add staff
        </Button>
      </Box>
      <Box>
        <DataTable columns={columns} data={data} />
      </Box>
      <Box>
        <ReactPaginate
          className="pagination"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageChange}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </Box>
      <Drawer onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Add Staff`}</DrawerHeader>
          <DrawerBody>
            <InviteForm onSubmitCb={onInvitationSubmit} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default StaffList;
