import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SendIcon } from "../CustomSvgIcons/CustomSvgIcons";
import draftToHtml from "draftjs-to-html";

interface EmailFormProps {
  id: number;
  onEmailSend?: (
    emailSubject: string,
    emailContent: string,
    id: number
  ) => void;
}

const EmailForm: FC<EmailFormProps> = ({ onEmailSend, id }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [isLoading, setIsLoading] = useState(false);

  const [emailSubject, setEmailSubject] = useState("");

  const onSendClick = () => {
    // Send email logic
    const emailContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    if (onEmailSend) {
      setIsLoading(true);
      onEmailSend(emailSubject, emailContent, id);
      setIsLoading(false);
    }
  };

  const onSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailSubject(e.target.value);
  };

  return (
    <>
      <Box>
        <Flex>
          <FormControl isRequired>
            <FormLabel>Subject:</FormLabel>
            <Input placeholder="Message title" onChange={onSubjectChange} />
            <FormLabel>Message:</FormLabel>
            <Box borderWidth="1px" p="2" mb="4">
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            </Box>
            <Flex justifyContent="right">
              <Button
                bg="brand.50"
                width="2xs"
                _hover={{ bg: "brand.100" }}
                color="white"
                leftIcon={<SendIcon height={34} width={34} color={"white"} />}
                onClick={onSendClick}
                isLoading={isLoading}
              >
                Send
              </Button>
            </Flex>
          </FormControl>
        </Flex>
      </Box>
    </>
  );
};

export default EmailForm;
