import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import DeleteIconAndModal from "../DeleteIconAndModal/DeleteIconAndModal";
import EmailDrawer from "../EmailDrawer/EmailDrawer";

interface ActionCellProps {
  id: number;
  route?: string;
  onEmailSend?: (
    emailSubject: string,
    emailContent: string,
    userId: number
  ) => void;
}

const ActionCell: React.FC<ActionCellProps> = ({
  id,
  route = "/",
  onEmailSend,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        bg="transparent"
        aria-label="Edit"
        icon={<EditIcon />}
        onClick={(event) => navigate(route)}
      />

      <DeleteIconAndModal />

      <EmailDrawer onEmailSend={onEmailSend} id={id} />
    </>
  );
};

export default ActionCell;
