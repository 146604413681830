import axios, { AxiosInstance } from "axios";
import { logger } from "../Logger";
import { ProfileData } from "../Types/ProfileData";
import { SubsidiaryData } from "../Components/SubsidaryList/SubsidiaryData";
import { PageRequest } from "../Types/PageRequest";
import { PageResponse } from "../Types/PageResponse";
import { Invitation, InvitationData, InviteAccept } from "../Types/Invitation";
import { UserData } from "../Types/UserData";
import { ProgramData } from "../Types/Program";
import { ExtendetEvent } from "../Types/ExtendedEvent";
import { PaymentData } from "../Types/PaymentData";
import { CouponData } from "../Components/CouponModal/CouponModal";
import { EmailContentProps } from "../Types/EmailContent";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export interface RegistrationData {
  email: string;
  password: string;
  profile: ProfileData;
}

export interface LoginData {
  email: string;
  password: string;
}

export type ResponseError = {
  description: string;
  status: number;
};

export class ApiService {
  private axiosInstance: AxiosInstance;

  constructor(baseURL: string | undefined = API_BASE_URL) {
    this.axiosInstance = axios.create({
      baseURL,
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        logger.log("Error interceptor!");
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          const refreshToken = localStorage.getItem("refreshToken");
          return axios
            .post("/api/authentication/email/refresh-token", { refreshToken })
            .then((response) => {
              const { token } = response.data;

              logger.log("Switching to new token!");

              localStorage.setItem("token", token);

              // Retry the original request with the new token
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axios(originalRequest);
            })
            .catch(() => {
              logger.log("Redirecting to login");
              // Handle refresh token error or redirect to login
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              logger.log(error);
              const currentPath = window.location.pathname;
              logger.log(`/login?returnUrl=${encodeURIComponent(currentPath)}`);
              if (currentPath !== "/login")
                window.location.href = `/login?returnUrl=${encodeURIComponent(
                  currentPath
                )}`;
            });
        }

        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  async registerUser(data: RegistrationData) {
    return this.axiosInstance.post("/api/authentication/email/register", data);
  }

  async loginUser(data: LoginData) {
    return this.axiosInstance.post("/api/authentication/email/login", data);
  }

  async getProfile() {
    return this.axiosInstance.get<ProfileData>("/api/users/profile");
  }

  async updateProfile(data: ProfileData) {
    return this.axiosInstance.put("/api/users/profile", data);
  }

  async uploadProfileImage(data: FormData) {
    return this.axiosInstance.post("/api/users/profile/image", data);
  }

  async getProfileImage() {
    return this.axiosInstance.get("/api/users/profile/image", {
      responseType: "blob",
    });
  }

  async getShortProfile() {
    return this.axiosInstance.get("/api/users/profile/short");
  }

  async createSubsidiary(data: SubsidiaryData) {
    return this.axiosInstance.post("/api/subsidiary", data);
  }

  async getSubsidiaries(pageRequest: PageRequest) {
    return this.axiosInstance.get<PageResponse<SubsidiaryData>>(
      "/api/subsidiary",
      {
        params: {
          page: pageRequest.page,
          size: pageRequest.pageSize,
        },
      }
    );
  }

  async getAllSubsidiariesPaged(pageRequest: PageRequest) {
    return this.axiosInstance.get<PageResponse<SubsidiaryData>>(
      "/api/subsidiary",
      {
        params: {
          page: pageRequest.page,
          size: pageRequest.pageSize,
          byUserId: false,
        },
      }
    );
  }

  async getAllSubsidiaries() {
    return this.axiosInstance.get<SubsidiaryData[]>("/api/subsidiary/all");
  }

  async inviteNewStaff(data: Invitation, subsidiaryId: number) {
    return this.axiosInstance.post(
      `/api/subsidiary/${subsidiaryId}/staff/invite`,
      data
    );
  }

  async getStaff(pageRequest: PageRequest) {
    return this.axiosInstance.get<PageResponse<UserData>>(
      "/api/subsidiary/staff",
      {
        params: {
          page: pageRequest.page,
          size: pageRequest.pageSize,
        },
      }
    );
  }

  async getMembers(pageRequest: PageRequest) {
    return this.axiosInstance.get<PageResponse<UserData>>(
      "/api/subsidiary/members",
      {
        params: {
          page: pageRequest.page,
          size: pageRequest.pageSize,
        },
      }
    );
  }

  async inviteNewMember(data: Invitation[], subsidiaryId: number) {
    return this.axiosInstance.post(
      `/api/subsidiary/${subsidiaryId}/members/invite`,
      data
    );
  }

  async createProgram(data: ProgramData) {
    return this.axiosInstance.post("/api/programs", data);
  }

  async getPrograms(pageRequest: PageRequest) {
    return this.axiosInstance.get<PageResponse<ProgramData>>("/api/programs", {
      params: {
        page: pageRequest.page,
        size: pageRequest.pageSize,
      },
    });
  }

  async getAllPrograms() {
    return this.axiosInstance.get<ProgramData[]>("/api/programs/all");
  }

  async getInvitations(pageRequest: PageRequest) {
    return this.axiosInstance.get<PageResponse<InvitationData>>(
      "/api/invitations",
      {
        params: {
          page: pageRequest.page,
          size: pageRequest.pageSize,
        },
      }
    );
  }

  async acceptInvitation(data: InviteAccept) {
    return this.axiosInstance.post<InvitationData>(
      "/api/invitations/accept",
      data
    );
  }

  async declineInvitation(data: InviteAccept) {
    return this.axiosInstance.delete("/api/invitations/decline", { data });
  }

  async getStaffById(id: number) {
    return this.axiosInstance.get(`/api/staff/${id}`);
  }

  async updateUserById(data: ProfileData, id: number) {
    return this.axiosInstance.put(`/api/users/${id}/profile`, data);
  }

  async addTeacher(staffId: number, programId: number) {
    const data = {
      userId: staffId,
    };
    return this.axiosInstance.post(`/api/programs/${programId}/trainers`, data);
  }

  async getProgramDetailsById(programId: number) {
    return this.axiosInstance.get(`/api/programs/${programId}`);
  }

  async updateProgramById(programId: number, data: ProgramData) {
    return this.axiosInstance.put(`/api/programs/${programId}`, data);
  }

  async getAllStaff() {
    return this.axiosInstance.get("/api/subsidiary/staff/all");
  }

  async getSubsidiaryById(id: number) {
    return this.axiosInstance.get<SubsidiaryData>(`/api/subsidiary/${id}`);
  }

  async updateSubsidiaryById(id: number, data: SubsidiaryData) {
    return this.axiosInstance.put(`/api/subsidiary/${id}`, data);
  }

  async addNewSchedule(id: number, data: ExtendetEvent) {
    return this.axiosInstance.post(`/api/subsidiary/${id}/schedules`, data);
  }

  async getSchedulesForSubsidiary(id: number) {
    return this.axiosInstance.get<ExtendetEvent[]>(
      `/api/subsidiary/${id}/schedules`
    );
  }

  async checkoutProgram(programId: number) {
    return this.axiosInstance.post(`/api/programs/${programId}/checkout`);
  }

  async getMySubscriptions() {
    return this.axiosInstance.get("/api/users/profile/subscriptions");
  }

  async getSubscriptionsByUserId(id: Number) {
    return this.axiosInstance.get(`/api/users/${id}/profile/subscriptions`);
  }

  async deleteSubscription(subId: string) {
    return this.axiosInstance.delete(`/api/payments/subscriptions/${subId}`);
  }

  async getProfileByUserId(userId: number) {
    return this.axiosInstance.get(`/api/users/${userId}/profile`);
  }

  async updateProfileForUser(data: ProfileData, userId: number) {
    return this.axiosInstance.put(`/api/users/${userId}/profile`, data);
  }

  async getPaymentsForUserById(id: number, pageRequest: PageRequest) {
    return this.axiosInstance.get<PageResponse<PaymentData>>(
      `/api/users/${id}/profile/payments`,
      {
        params: {
          page: pageRequest.page,
          size: pageRequest.pageSize,
        },
      }
    );
  }

  async getPayments(from: Date, to: Date, pageRequest: PageRequest) {
    return this.axiosInstance.get<PageResponse<PaymentData>>(`/api/payments`, {
      params: {
        page: pageRequest.page,
        size: pageRequest.pageSize,
        from: from,
        to: to,
      },
    });
  }

  async getSubsidiaryOffer(id: number) {
    return this.axiosInstance.get(`/api/subsidiary/${id}/offer`);
  }

  async getConnectAccountId() {
    return this.axiosInstance.get("/api/payments/connect-account");
  }

  async createConenctAccount() {
    return this.axiosInstance.post("/api/payments/connect-account");
  }

  async generateCoupon(id: number | undefined, couponData: CouponData) {
    return this.axiosInstance.post(`/api/programs/${id}/coupons`, couponData);
  }

  async sendEmailToUser(emailContentDto: EmailContentProps, userId: number) {
    return this.axiosInstance.post(
      `/api/users/${userId}/email`,
      emailContentDto
    );
  }

  async sendEmailToSubsidiaryMembers(
    emailContentDto: EmailContentProps,
    subsidiaryId: number
  ) {
    return this.axiosInstance.post(
      `/api/subsidiary/${subsidiaryId}/email`,
      emailContentDto
    );
  }

  async sendEmailToProgramMembers(
    emailContentDto: EmailContentProps,
    subsidiaryId: number
  ) {
    return this.axiosInstance.post(
      `/api/programs/${subsidiaryId}/email`,
      emailContentDto
    );
  }
}

export const apiService = new ApiService();
